@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;600&family=Roboto:wght@300;400&display=swap);
body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

nav {
  position: relative;
  transition: 0.5s;
  color: black;
}

nav > div,
nav > ul {
  position: fixed;
}

nav .navbar-toggler {
  display: none;
}

nav .close-icon {
  cursor: pointer;
  left: 30px;
  top: 13px;
  font-size: 22px;
  color: rgb(202, 199, 199);
}

nav .navbar-logo a,
nav .navbar-logo svg {
  letter-spacing: 2px;
  color: #007bff;
  font-size: 25px;
  font-weight: 600;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  font-family: 'Roboto', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

nav .nav {
  transition: 0.2s;
}

nav .nav .nav-item a,
nav .nav .nav-item svg {
  font-size: 17px !important;
  font-weight: 400;
  color: #676b84 !important;
  padding-bottom: 2px;
  margin-right: 2px;
  transition: 0.5s;
}

nav .nav .nav-item svg {
  font-size: 22px !important;
  margin-right: 10px;
}

nav .nav .nav-item:hover a,
nav .nav .nav-item:hover svg {
  color: rgba(0, 0, 0, 0.774) !important;
}

nav.close {
  padding: 0 !important;
}

nav.close .navbar-logo {
  display: none !important;
}

nav.close .navbar-toggler {
  display: block;
  opacity: 1;
  visibility: visible;
  outline: none;
  border: none;
  margin: 15px 0 0 0 !important;
  align-self: center;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}

nav.close .close-icon {
  transition: 0.5s;
  opacity: 0;
}

nav.close .nav.closen-nav {
  top: 50px;
}

nav.close .nav.closen-nav .nav-item .nav-link svg {
  font-size: 22px;
  margin: 15px 0;
}

@media screen and (max-width: 575.99px) {
  nav .close-icon {
    top: 10px;
    left: 90%;
  }
}

.cards .card {
  width: 240px;
}

.cards .card .card-header,
.cards .card .card-header .icon svg {
  color: #e3cece;
  font-size: 25px;
}

.cards .card .card-header h3 {
  font-size: 19px;
  text-transform: uppercase;
}

.cards .card .card-body .increasement-percent {
  color: #2dce89;
  font-weight: 600;
}

/* Start Charts */
.charts div {
  width: 420px;
}

@media screen and (max-width: 360px) {
  .charts div {
    width: 250px;
  }
}

@media screen and (min-width: 361px) and (max-width: 767.99) {
  .charts div {
    width: 360px;
  }
}

@media screen and (min-width: 991.99px) {
  .cards {
    margin-top: -85px;
    margin-bottom: 10px;
  }
}

/* End Charts */

/* Start Workers Table */
.workers-table h2 {
  text-align: center;
}

.workers-table table {
  min-height: 250px;
}

.workers-table table .field {
  border: 1px solid;
  background: #fff;
  padding: 1px;
  border-radius: 3px;
}

.workers-table table .field:disabled {
  padding: 0;
  border: 0;
  outline: 0;
}

.workers-table .actions {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.workers-table table .actions .btn {
  transition: 0.5s;
  white-space: nowrap;
}

.workers-table table .edit-alert {
  position: relative;
}

.workers-table table .edit-alert::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 17px;
  right: -16px;
  border: 8px solid #0d6efd;
  border-bottom-color: #fff0;
  border-right-color: #fff0;
  border-top-color: #fff0;
}

.workers-table table .edit-alert svg {
  cursor: pointer;
}

@media screen and (max-width: 991.99px) {
  .workers-table {
    overflow-x: scroll;
  }

  .workers-table h2 {
    width: 147px;
  }

  .workers-table table {
    table-layout: initial;
  }

  .workers-table .btn {
    margin-bottom: 6px;
  }

  .workers-table .actions .btn {
    width: 125px;
    padding: 5px;
  }
}

@media screen and (min-width: 992px) {
  .workers-table table {
    table-layout: fixed;
    overflow: hidden;
  }

  .workers-table tr,
  .workers-table tr td input {
    text-align: center;
  }

  .workers-table table .field {
    width: 100%;
  }
}

/* End Workers Table */

/* Start Popup Form */
.pop-up-form {
  z-index: 100;
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.822);
  transition: 0.5s;
}

.pop-up-form svg {
  float: right;
  cursor: pointer;
  font-size: 22px;
  margin: 20px;
  color: rgba(0, 0, 0, 0.692);
}

.pop-up-form form {
  width: 80%;
  margin: auto;
  -webkit-transform: translateY(35%);
          transform: translateY(35%);
  border: 1px solid;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
}

.pop-up-form form .form-control {
  background-color: inherit !important;
  border: none !important;
  text-align: left;
}

.pop-up-form form .form-control input {
  border-radius: 5px;
  outline: none;
  border: 1px solid;
}

.pop-up-form form .form-control .error {
  color: red;
}

.pop-up-form form .btn {
  width: 50%;
  font-size: 20px;
}

@media screen and (max-width: 576px) {
  .pop-up-form form {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
}

/* End Popup Form */

/* Start Fonts */

/*
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
*/

/* End Fonts */

/* Import CSS Files */

/* Start Global */
html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', serif;
}

p,
li {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

/* End Global */

/* Start Header */
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header .icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 50px;
}

.header .icons div {
  position: relative;
  cursor: pointer;
  margin: 0 3px;
}

.header .icons div span.red-dot {
  background-color: red;
  padding: 5px 5px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 1;
}

.header .icons div:nth-child(1) span.red-dot {
  top: 5px;
  right: 3px;
}

.header .profile-info {
  position: relative;
}

.header .profile-info .image {
  max-width: 77px;
  max-height: 77px;
  cursor: pointer;
}

.header .profile-info .info {
  position: absolute;
  top: 90px;
  right: 50%;
  -webkit-transform: translateX(15%);
          transform: translateX(15%);
  width: 180px;
  background-color: #4d4f5c;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  transition: 0.5s;
  z-index: 10;
}

.header .profile-info .info::after {
  content: '';
  border: 10px solid #4d4f5c;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -17px;
  right: 18px;
  z-index: 1;
}

.header .profile-info .info h5 {
  color: #828bb2;
  font-size: 13px;
}

.header .profile-info .info h6 {
  font-size: 12px;
  text-align: center;
}

.header .profile-info .info ul li a {
  font-size: 14px !important;
}

@media screen and (min-width: 991.99px) {
  .header {
    padding-top: 0 !important;
    height: 280px;
  }
}

/* End Header */

/* Start Footer */
footer {
  margin: 20px 0 0 0;
  padding: 20px;
  text-align: center;
}

footer p {
  font-size: 18px;
  color: #fff;
  margin: 0;
}

